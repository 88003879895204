const types = {
  painting: "painting",
  print: "print",
  digital: "digital",
};

export const art = [
  {
    title: "interdimensional-harvester-scout-II",
    alt: "alien fruit harvesting logs",
    isSold: false,
    type: types.painting,
    year: "2023",
    size: "44 x 44 in",
    media: "acrylic on hardboard cradle panel",
  },
  {
    title: "concurrency",
    alt: "landscape depicting cut logs",
    isSold: false,
    type: types.painting,
    year: "2023",
    size: "24 x 36 in",
    media: "acrylic on hardboard cradle panel",
  },
  {
    title: "expansion-contract",
    alt: "robot typing on keyboard in abstract landscape",
    isSold: true,
    type: types.painting,
    year: "2015",
    size: "24 x 24 in",
    media: "acrylic & ink on wood",
  },
  {
    title: "skull",
    alt: "artwork depicting robot catching butterfly",
    isSold: false,
    type: types.print,
    year: "2015",
    size: "11 x 14 in",
    media: "digital",
  },
  {
    title: "master-gardener",
    alt: "artwork depicting robot catching butterfly",
    isSold: false,
    type: types.painting,
    year: "2016",
    size: "36 x 36 in",
    media: "acrylic & ink on panel",
  },
  {
    title: "snoozing",
    alt: "painting of man taking a nap",
    isSold: true,
    type: types.painting,
    isPainting: true,
    year: "2012",
    size: "24 x 24 in",
    media: "acrylic on wood",
  },
  {
    title: "bicycle",
    alt: "bicycle artwork",
    isSold: false,
    type: types.digital,
    year: "2014",
    media: "digital",
  },
  {
    title: "catching-the-butteryfly",
    alt: "artwork depicting robot catching butterfly",
    isSold: false,
    type: types.digital,
    year: "2012",
    media: "digital",
  },
  {
    title: "lds-in-60s",
    alt: "abstract landscape",
    isSold: false,
    type: types.painting,
    year: "2015",
    size: "24 x 24 in",
    media: "acylic & ink on wood",
  },
  {
    title: "coal-powered",
    alt: "robot and cat",
    isSold: true,
    type: types.digital,
    year: "2014",
    media: "digital",
  },
  {
    title: "cove-molding",
    alt: "abstract landscape painting of a shoreline",
    isSold: false,
    type: types.painting,
    year: "2015",
    size: "24 x 24 in",
    media: "acrylic & ink on wood",
  },
  {
    title: "dang-doodle",
    alt: "abstract painting",
    isSold: true,
    type: types.painting,
    year: "2015",
    size: "12 x 12 in",
    media: "acrylic & ink on wood",
  },
  {
    title: "doodle",
    alt: "abstract artwork",
    isSold: false,
    type: types.digital,
    year: "2014",
    media: "digital",
  },
  {
    title: "doodle-2",
    alt: "abstract artwork",
    isSold: false,
    type: types.digital,
    year: "2014",
    media: "digital",
  },
  {
    title: "egg-drop",
    alt: "artwork depicting robot catching butterfly",
    isSold: false,
    type: types.painting,
    year: "2015",
    size: "12 x 12 in",
    media: "acrylic & ink on wood",
  },
  {
    title: "invasion",
    alt: "squid creature & forest",
    isSold: false,
    type: types.painting,
    year: "2013",
    size: "24 x 24 in",
    media: "acrylic & ink on wood",
  },
  {
    title: "little-house",
    alt: "house in forest",
    isSold: false,
    type: types.print,
    year: "2014",
    media: "digital",
  },
  {
    title: "pizza-forest",
    alt: " abstract forest with pizza",
    isSold: false,
    type: types.print,
    year: "2014",
    media: "digital",
  },
];
